import { getMeetingByID, MeetingDetails } from '@/services/meeting'
import { produce } from 'immer'
import { StateCreator } from 'zustand'
import useStore, { IStore } from './store'
import {
  extractActiveHearingLAR,
  extractActiveLAR,
  extractActiveOOR,
  extractCredentialIssuanceStep,
  extractCurrentLARAuthCount,
  extractIHeard,
  extractJitsiMessage,
  extractJoinMultisigMember,
  extractLARChallengeMessages,
  extractLARVotes,
  extractLeadQAR,
  extractLEMultisigAidInitiator,
  extractMultisigMemberSignal,
  extractMultiSigSigner,
  extractOORAuth,
  extractQARCeremonyStep,
  extractQARChallengeMessages,
  extractRoleCredRecipients,
  extractSecondaryQARApproval,
  groupDevicesByKind,
  prepareActiveLAR,
  prepareCurrentLARAuthCount,
  prepareQARCeremonySteps,
  uniqChallengeString
} from '@/utils/ceremony'
import {
  CEREMONY_TYPE,
  FETCHING_STATUS,
  PHOTO_LOADING_STATUS
} from '@/config/constants'
import { Recipient } from '@/features/oor/api'
import { GroupAid } from './signify'
import { IssueCredentialResult } from 'signify-ts'
import { cloneDeep } from 'lodash'
import {
  JitsiMessageCreator,
  JitsiMessagesEnum,
  MessagePayloadTypes
} from '@/state/models'
import type { PossibleOorRecipientDto } from '@/api/origin-workflow-svc'
import constants from '@/config/constants'
import { ceremonyTypeFromURL } from '@/utils/common'
import { isLAR, isQAR } from '@/utils/auth'
import { isRecipient } from '@/features/oor'
import { AuthStatus } from '@/state/authentication'

export enum JITSI_CONNECTION_STATUS {
  NOT_CONNECTED,
  CONNECTED,
  JOINED,
  DISCONNECTED
}

interface IIdVersePhotoAvailability {
  available: boolean
  url: string | null
  fetchingStatus: FETCHING_STATUS
  photoLoadingStatus: PHOTO_LOADING_STATUS
}

export type Identifiers = {
  name: string
  prefix: string
  oobi: string[]
  participantId?: string
  isMultisig: boolean
  group?: GroupAid
}

export type Participant = {
  id: string
  jitsiUserId: string
  name: string
  orgId: string
  orgName: string
  verified: boolean
  photo: string
  muted: boolean
  videoOff: boolean
  role: string[]
  identifiers: Identifiers[]
  idVersePhotoAvailable?: IIdVersePhotoAvailability
}

export enum TriggerSignify {
  WAITING = 'WAITING',
  ACCEPT = 'ACCEPT',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED'
}

export type ChallengeString = {
  from: string
  to: string
  challengeString: string
  triggerSignify: TriggerSignify
  completed: boolean
}

export type AIDVerificationString = {
  from: { participantId: string; completionStatus: boolean }[]
  to: string
  triggerSignify: TriggerSignify
  completed: boolean
  messageId: string
}

export enum QAR_CEREMONY_STEP {
  PHOTO_VERIFY,
  START_CEREMONY,
  AID_VERIFICATION,
  QAR_CHALLENGE,
  QAR_CHALLENGE_OOR,
  QAR_OOR_CEREMONY_STARTED,
  QAR_CHALLENGE_STARTED,
  LAR_CHALLENGE,
  LAR_CHALLENGE_STARTED,
  OOR_AUTH_PRE,
  OOR_AUTH_STARTED,
  OOR_AUTH_FINAL,
  MULTI_SIG_STARTED,
  COMPLETED,
  END
}

export enum CredentialIssuanceStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED'
}

export interface ILARVote {
  id: string
  accepted: boolean
}

export interface IOORAuth {
  id: string
  votes: any[]
  qarApproval: string[]
}

export interface RoleCredentialRecipient {
  userId: string
  approvalVotes: string[]
  recipientAid: string
  issuerAid: string
  authCredentialRecipientAid: string
  schema: string
  credentialSaid?: string
  credentialAttributes?: any
  issuerMembers?: CredentialIssuanceMember[]
  status: CredentialIssuanceStatus
  error?: any
}

export interface CredentialIssuanceMember {
  issuedBy: string
  aid: string
  isMaker: boolean
  issuerTo: string
  said: string
  hasJoinedCreateCredential?: boolean
  hasJoinedGrantCredential?: boolean
  hasJoinedAdmitCredential?: boolean
}

/**
 * Interface representing the state of a Legal Entity (LE) vLEI credential issuance.
 *
 * @interface LECredentialIssuanceState
 * @property {string} schema - Schema SAID of LE credential
 * @property {string} credentialSaid - SAID of LE credential
 * @property {string} recipientAid - Recipient AID (LE group AID)
 * @property {string} issuerAid - Issuer AID (QVI group AID)
 * @property {CredentialIssuanceMember[]} [issuerMembers] - array of members associated with the issuer aid
 */
export interface LECredentialIssuanceState {
  schema: string
  credentialSaid: string
  recipientAid: string
  issuerAid: string
  issuerMembers?: CredentialIssuanceMember[]
}

export interface IMultisigAid {
  groupAlias: string
  members?: IMultisigMember[]
}

export interface IMultisigMember {
  userId: string
  localAid: string
  localAidName: string
  isMaker: boolean
  hasJoinedIcp?: boolean
  hasJoinedEndRoleAuth?: boolean
  hasJoinedRegistry?: boolean
  hasCompletedIcpOp?: boolean
  agents?: IMultisigAidAgent[]
}

export interface IMultisigAidAgent {
  eid: string
  isAuthorized: boolean
}

export interface ICreateCredentialResult {
  roleRecipientId: string
  result: IssueCredentialResult
}

export interface IMultisigMemberSignal {
  sender: string
  target?: string
  targetType?: string
  msgType: MULTISIG_AID_MESSAGE_TYPE
  groupAid: string
  groupAidName: string
  data: any
}

export enum MULTISIG_AID_MESSAGE_TYPE {
  START_MULTISIG_AID_INCEPTION = 'START_MULTISIG_AID_INCEPTION',
  JOIN_MULTISIG_AID_INCEPTION = 'JOIN_MULTISIG_AID_INCEPTION',
  MEMBER_JOINED_MULTISIG_AID_INCEPTION = 'MEMBER_JOINED_MULTISIG_AID_INCEPTION',
  JOIN_REGISTRY_INCEPTION = 'JOIN_REGISTRY_INCEPTION',
  JOIN_END_ROLE_AUTH = 'JOIN_END_ROLE_AUTH',
  MEMBER_JOINED_END_ROLE_AUTH = 'MEMBER_JOINED_END_ROLE_AUTH',
  MEMBER_JOINED_REGISTRY_INCEPTION = 'MEMBER_JOINED_REGISTRY_INCEPTION',
  LE_AID_INCEPTION_COMPLETE = 'LE_AID_INCEPTION_COMPLETE',
  JOIN_CREATE_CREDENTIAL = 'JOIN_CREATE_CREDENTIAL',
  MEMBER_JOINED_CREATE_CREDENTIAL = 'MEMBER_JOINED_CREATE_CREDENTIAL',
  JOIN_GRANT_CREDENTIAL = 'JOIN_GRANT_CREDENTIAL',
  MEMBER_JOINED_GRANT_CREDENTIAL = 'MEMBER_JOINED_GRANT_CREDENTIAL',
  JOIN_ADMIT_CREDENTIAL = 'JOIN_ADMIT_CREDENTIAL',
  MEMBER_JOINED_ADMIT_CREDENTIAL = 'MEMBER_JOINED_ADMIT_CREDENTIAL',
  LE_CREDENTIAL_ISSUE_COMPLETE = 'LE_CREDENTIAL_ISSUE_COMPLETE',
  AUTH_CREDENTIAL_ISSUE_COMPLETE = 'AUTH_CREDENTIAL_ISSUE_COMPLETE',
  ROLE_CREDENTIAL_ISSUE_COMPLETE = 'ROLE_CREDENTIAL_ISSUE_COMPLETE',
  ROLE_CREDENTIAL_RECEIVED = 'ROLE_CREDENTIAL_RECEIVED',
  WAIT_KERIA_OPERATION_COMPLETION = 'WAIT_KERIA_OPERATION_COMPLETION',
  OPERATION_COMPLETED = 'OPERATION_COMPLETED',
  ALL_AUTH_CREDENTIALS_ISSUANCE_COMPLETE = 'ALL_AUTH_CREDENTIALS_ISSUANCE_COMPLETE',
  LE_ADMIT_CREDENTIAL_COMPLETE = 'LE_ADMIT_CREDENTIAL_COMPLETE',
  SELF_SIMULATE_JOINED_CREATE_CREDENTIAL = 'SELF_SIMULATE_JOINED_CREATE_CREDENTIAL',
  SELF_SIMULATE_JOINED_GRANT_CREDENTIAL = 'SELF_SIMULATE_JOINED_GRANT_CREDENTIAL'
}

export type CreateLEMultisigAidMessagePayload = {
  msgType: string
  initiatorId: string
  alias: string
  members: string[]
}

export interface ICredentialIssuanceStep {
  messageId: string
  params?: { [key: string]: string }
}

export interface ILeadQAR {
  leadQAR: string
}

export interface ISecondaryQARApproval {
  secondaryQAR: string
}

export interface CredentialSaid {
  receiver?: string
  said: string
  type: string
}

export interface ICredentialIssuanceStatusChange {
  status: CredentialIssuanceStatus
}

export type IMeetStore = {
  meetingDetails: { details: MeetingDetails; fetchingStatus: FETCHING_STATUS }
  conferenceStatus: JITSI_CONNECTION_STATUS
  devices: {
    audioInput: MediaDeviceInfo[]
    audioOutput: MediaDeviceInfo[]
    videoInput: MediaDeviceInfo[]
  }
  ceremonySteps: {
    credentialIssuanceStatus: CredentialIssuanceStatus
    credentialSaid: CredentialSaid[]
    leadQAR: string
    activeLAR: string
    activeHearingLAR: string
    activeOOR: string
    qarCurrentStep: QAR_CEREMONY_STEP
    participants: Participant[]
    Recipients: PossibleOorRecipientDto[]
    currentLARAuthCount: number
    AIDVerification: {
      AIDTodos: AIDVerificationString[]
      stepCompleted: boolean
      AIDTodosInProgress: boolean
    }
    QARChallenge: {
      ChallengeStrings: ChallengeString[]
      completed: boolean
    }
    LARChallenge: {
      LARChallenges: ChallengeString[]
      completed: boolean
    }
    OORAuth: {
      recipients: IOORAuth[]
      completed: boolean
      roleCredentialRecipients: RoleCredentialRecipient[]
    }
    multiSig: {
      selectedSigner: number
      LARVotes: ILARVote[]
      leMultisigAidInceptPayload: CreateLEMultisigAidMessagePayload
      joinLeMultisigAidPayload: CreateLEMultisigAidMessagePayload
      multisigMemberSignal: IMultisigMemberSignal
    }
    eventLogs: string[]
    credentialIssuanceStep: ICredentialIssuanceStep
  }
  setRoom: (room) => void
  setJitsiConnection: (connection) => void
  addParticipants: (participant: Participant) => void
  removeParticipant: (id: string) => void
  updateParticipant: (participant: Participant) => void
  updateDevices: (devices: MediaDeviceInfo[]) => void
  setConferenceStatus: (status: JITSI_CONNECTION_STATUS) => void
  getMeetingDetails: (meetingId: string) => void
  setQARCurrentStep: (step: QAR_CEREMONY_STEP) => void
  sendMessage: (msg: string | object) => void
  endCeremony: () => void
  setLEMultisigInceptPayload: (
    payload: CreateLEMultisigAidMessagePayload
  ) => void
  logEvents: (eventMessages: string[]) => void
  setRecipient: (recipients: PossibleOorRecipientDto[]) => void
  setCurrentLARAuthCount: (currentLARAuthCount: number) => void
  initMeetSubscribers: () => void
  generateLARsVerificationsForAID: () => void
  iSaidItForAID: (todo: AIDVerificationString) => void
  iHeardItForAID: (todo: AIDVerificationString) => void
  checkAndActivateLARForAID: (
    todos: (AIDVerificationString | ChallengeString)[]
  ) => void
  resetMeetStore: () => void
}

let conferenceRoom: any
let jitsiConnection: any

const initialState = {
  conferenceStatus: JITSI_CONNECTION_STATUS.NOT_CONNECTED,
  ceremonySteps: {
    credentialIssuanceStatus: CredentialIssuanceStatus.NOT_STARTED,
    credentialSaid: [],
    leadQAR: null,
    activeLAR: null,
    activeHearingLAR: null,
    activeOOR: null,
    currentLARAuthCount: 0,
    participants: [],
    Recipients: [],
    qarCurrentStep: QAR_CEREMONY_STEP.PHOTO_VERIFY,
    AIDVerification: {
      AIDTodos: [],
      stepCompleted: false,
      AIDTodosInProgress: false
    },
    QARChallenge: {
      ChallengeStrings: [],
      completed: false
    },
    LARChallenge: {
      LARChallenges: [],
      completed: false
    },
    OORAuth: {
      recipients: [],
      completed: false,
      roleCredentialRecipients: []
    },
    multiSig: {
      selectedSigner: 0,
      LARVotes: [],
      leMultisigAidInceptPayload: null,
      joinLeMultisigAidPayload: null,
      multisigMemberSignal: null
    },
    eventLogs: [],
    credentialIssuanceStep: null
  },
  devices: {
    audioInput: [],
    audioOutput: [],
    videoInput: []
  },
  meetingDetails: {
    details: {} as MeetingDetails,
    fetchingStatus: FETCHING_STATUS.NOT_INITIATED
  }
}
const meetStore: StateCreator<IStore, [], [], IMeetStore> = (set, get) => ({
  ...initialState,
  setRoom: (room) => {
    conferenceRoom = room

    room.on(JitsiMeetJS.events.conference.MESSAGE_RECEIVED, (_id, message) => {
      let payload
      const extractedJitsiMsg = extractJitsiMessage(message)
      // "{"QARCeremonySteps":3}"
      console.log('============MESSAGE_RECEIVED', message)

      // todo remove this if after all payloads are updated
      if (extractedJitsiMsg) {
        switch (extractedJitsiMsg.generalJitsiMessage) {
          case JitsiMessagesEnum.AID_VERIFICATION_GENERATE_LARS_TODOS:
            payload =
              extractedJitsiMsg.generalJitsiPayload as MessagePayloadTypes[JitsiMessagesEnum.AID_VERIFICATION_GENERATE_LARS_TODOS]

            set(
              produce((state: IMeetStore) => {
                state.ceremonySteps.AIDVerification.AIDTodos = [...payload]
              })
            )
            break
          case JitsiMessagesEnum.AID_VERIFICATION_I_Heard_It:
          case JitsiMessagesEnum.AID_VERIFICATION_I_Said_It:
            payload = extractedJitsiMsg.generalJitsiPayload as
              | MessagePayloadTypes[JitsiMessagesEnum.AID_VERIFICATION_I_Heard_It]
              | MessagePayloadTypes[JitsiMessagesEnum.AID_VERIFICATION_I_Said_It]

            set(
              produce((state: IMeetStore) => {
                let index =
                  state.ceremonySteps.AIDVerification.AIDTodos.findIndex(
                    (todo) => todo.messageId === payload.messageId
                  )
                if (index !== -1) {
                  state.ceremonySteps.AIDVerification.AIDTodos[index] = payload
                } else {
                  console.log('message not found on jitsi')
                }
              })
            )
            break
          case JitsiMessagesEnum.SET_CREDENTIAL_SAID:
            payload =
              extractedJitsiMsg.generalJitsiPayload as MessagePayloadTypes[JitsiMessagesEnum.SET_CREDENTIAL_SAID]
            set(
              produce((state: IMeetStore) => {
                state.ceremonySteps.credentialSaid.push(payload)
              })
            )
            break
          case JitsiMessagesEnum.CREDENTIAL_ISSUANCE_STATUS_CHANGE:
            payload =
              extractedJitsiMsg.generalJitsiPayload as MessagePayloadTypes[JitsiMessagesEnum.CREDENTIAL_ISSUANCE_STATUS_CHANGE]
            set(
              produce((state: IMeetStore) => {
                state.ceremonySteps.credentialIssuanceStatus = payload.status
              })
            )
            break
        }
      }

      const currentStep = extractQARCeremonyStep(message)
      if (currentStep) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.qarCurrentStep = currentStep
          })
        )
      }

      const QARChallengeMessage = extractQARChallengeMessages(message)
      if (QARChallengeMessage) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.QARChallenge.ChallengeStrings =
              uniqChallengeString(
                QARChallengeMessage,
                get().ceremonySteps.QARChallenge.ChallengeStrings
              )
          })
        )
      }

      const LARChallengeMessage = extractLARChallengeMessages(message)
      if (LARChallengeMessage) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.LARChallenge.LARChallenges =
              uniqChallengeString(
                LARChallengeMessage,
                get().ceremonySteps.LARChallenge.LARChallenges
              )
          })
        )
      }

      const IHeard = extractIHeard(message)
      if (IHeard) {
        set(
          produce((state: IMeetStore) => {
            let index =
              state.ceremonySteps.QARChallenge.ChallengeStrings.findIndex(
                (lc) => lc.from === IHeard.from && lc.to === IHeard.to
              )
            if (index > -1) {
              state.ceremonySteps.QARChallenge.ChallengeStrings[index] = IHeard
            } else {
              index = state.ceremonySteps.LARChallenge.LARChallenges.findIndex(
                (lc) => lc.from === IHeard.from && lc.to === IHeard.to
              )
              state.ceremonySteps.LARChallenge.LARChallenges[index] = IHeard
            }
          })
        )
      }

      const activeLAR = extractActiveLAR(message)
      if (activeLAR) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.activeLAR = activeLAR
          })
        )
      }
      const activeHearingLAR = extractActiveHearingLAR(message)
      if (activeHearingLAR) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.activeHearingLAR = activeHearingLAR
          })
        )
      }

      const activeOOR = extractActiveOOR(message)
      if (activeOOR) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.activeOOR = activeOOR
          })
        )
      }

      const selectedSigner = extractMultiSigSigner(message)
      if (selectedSigner) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.multiSig.selectedSigner = selectedSigner
          })
        )
      }

      const LARVote = extractLARVotes(message)
      if (LARVote) {
        set(
          produce((state: IMeetStore) => {
            const existingLARVote =
              state.ceremonySteps.multiSig.LARVotes.findIndex(
                (lv) => lv.id === LARVote.id
              )
            if (existingLARVote > -1)
              state.ceremonySteps.multiSig.LARVotes[existingLARVote] = LARVote
            else state.ceremonySteps.multiSig.LARVotes.push(LARVote)
          })
        )
      }

      const leVleiMultisigInitiatorPayload =
        extractLEMultisigAidInitiator(message)
      if (leVleiMultisigInitiatorPayload) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.multiSig.leMultisigAidInceptPayload =
              leVleiMultisigInitiatorPayload
          })
        )
      }

      const joinLeMultisigAidPayload = extractJoinMultisigMember(message)
      if (joinLeMultisigAidPayload) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.multiSig.joinLeMultisigAidPayload =
              joinLeMultisigAidPayload
          })
        )
      }

      const OORAuth = extractOORAuth(message)
      if (OORAuth) {
        set(
          produce((state: IMeetStore) => {
            const existingOORAuth =
              state.ceremonySteps.OORAuth.recipients.findIndex(
                (c) => c.id === OORAuth.id
              )
            if (existingOORAuth > -1) {
              state.ceremonySteps.OORAuth.recipients[existingOORAuth] = OORAuth
            } else state.ceremonySteps.OORAuth.recipients.push(OORAuth)
          })
        )
      }

      // const OORAuthCan = extractRoleAuthCandidate(message)
      // if (OORAuthCan) {
      //   set(
      //     produce((state: IMeetStore) => {
      //       const existingOORAuth =
      //         state.ceremonySteps.OORAuth.approvedCandidates.findIndex(
      //           (c) => c.id === OORAuthCan.id
      //         )
      //       if (existingOORAuth > -1) {
      //         state.ceremonySteps.OORAuth.approvedCandidates[existingOORAuth] =
      //           OORAuthCan
      //       } else
      //         state.ceremonySteps.OORAuth.approvedCandidates.push(OORAuthCan)
      //     })
      //   )
      // }

      const roleCredRecipients = extractRoleCredRecipients(message)
      if (roleCredRecipients) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.OORAuth.roleCredentialRecipients =
              roleCredRecipients
          })
        )
      }

      // const roleCredIssuer = extractRoleCredIssuer(message)
      // if (roleCredIssuer) {
      //   set(
      //     produce((state: IMeetStore) => {
      //       const index =
      //         state.ceremonySteps.OORAuth.approvedCandidates.findIndex(
      //           (c) => c.id === roleCredIssuer.issuerTo
      //         )
      //       if (index > -1) {
      //         let record = state.ceremonySteps.OORAuth.approvedCandidates[index]
      //         let issuerIndx = record.issuerMembers.findIndex(
      //           (c) => c.issuerTo === roleCredIssuer.issuerTo
      //         )

      //         if (issuerIndx > -1) {
      //           state.ceremonySteps.OORAuth.approvedCandidates[
      //             index
      //           ].issuerMembers[issuerIndx] = roleCredIssuer
      //         } else {
      //           state.ceremonySteps.OORAuth.approvedCandidates[
      //             index
      //           ].issuerMembers.push(roleCredIssuer)
      //         }
      //       }
      //     })
      //   )
      // }

      const MultisigMemberSignal = extractMultisigMemberSignal(message)
      if (MultisigMemberSignal) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.multiSig.multisigMemberSignal =
              MultisigMemberSignal
          })
        )
      }

      const credentialIssuanceStep = extractCredentialIssuanceStep(message)
      if (credentialIssuanceStep) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.credentialIssuanceStep = credentialIssuanceStep
          })
        )
      }

      const currentLARAuthCount = extractCurrentLARAuthCount(message)
      if (currentLARAuthCount) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.currentLARAuthCount = currentLARAuthCount
          })
        )
      }

      const leadQAR = extractLeadQAR(message)
      if (leadQAR) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.leadQAR = leadQAR.leadQAR
          })
        )
      }

      const secondaryQARApproval = extractSecondaryQARApproval(message)
      if (secondaryQARApproval) {
        set(
          produce((state: IMeetStore) => {
            state.ceremonySteps.OORAuth.recipients.forEach((recipient) => {
              if (
                !recipient.qarApproval.includes(
                  secondaryQARApproval.secondaryQAR
                )
              )
                recipient.qarApproval.push(secondaryQARApproval.secondaryQAR)
            })
          })
        )
      }
    })
  },

  setJitsiConnection: (connection) => {
    jitsiConnection = connection
  },
  setConferenceStatus: (conferenceStatus) => {
    set(() => ({ conferenceStatus }))
  },
  addParticipants: (participant) => {
    set(
      produce((state: IMeetStore) => {
        const participantExist = !!state.ceremonySteps.participants.filter(
          (p) => p.id === participant.id
        ).length

        if (!participantExist)
          state.ceremonySteps.participants.push(participant)
      })
    )
  },
  updateParticipant: (participant) => {
    set(
      produce((state: IMeetStore) => {
        const participantIndex = state.ceremonySteps.participants.findIndex(
          (p) => p.id === participant.id
        )

        state.ceremonySteps.participants[participantIndex] = {
          ...state.ceremonySteps.participants[participantIndex],
          ...participant
        }
      })
    )
  },
  updateDevices: (availableDevices) => {
    const devices = groupDevicesByKind(availableDevices)
    set(() => ({ devices }))
  },
  removeParticipant: (id) => {
    set(
      produce((state: IMeetStore) => {
        const participantExist = state.ceremonySteps.participants.filter(
          (p) => p.id !== id
        )

        state.ceremonySteps.participants = participantExist
      })
    )
  },
  getMeetingDetails: async (meetingId) => {
    set(
      produce((state: IMeetStore) => {
        state.meetingDetails.fetchingStatus = FETCHING_STATUS.FETCHING
      })
    )
    try {
      const details = await getMeetingByID(meetingId)
      set(
        produce((state: IMeetStore) => {
          ;(state.meetingDetails.details = details),
            (state.meetingDetails.fetchingStatus = FETCHING_STATUS.COMPLETE)
        })
      )
    } catch (ex) {
      set(
        produce((state: IMeetStore) => {
          ;(state.meetingDetails.details = {} as MeetingDetails),
            (state.meetingDetails.fetchingStatus = FETCHING_STATUS.FAILED)
        })
      )
    }
  },
  setCurrentLARAuthCount: (currentLARAuthCount) => {
    set(
      produce((state: IMeetStore) => {
        state.ceremonySteps.currentLARAuthCount = currentLARAuthCount
      })
    )

    conferenceRoom.sendMessage(prepareCurrentLARAuthCount(currentLARAuthCount))
  },
  setQARCurrentStep: (step) => {
    set(
      produce((state: IMeetStore) => {
        state.ceremonySteps.qarCurrentStep = step
      })
    )

    conferenceRoom.sendMessage(prepareQARCeremonySteps(step))
  },
  sendMessage: (msg) => {
    let message = msg

    if (typeof msg !== 'string') {
      message = JSON.stringify(message)
    }

    conferenceRoom.sendMessage(message)
  },
  endCeremony: () => {
    const { setQARCurrentStep } = get()
    setQARCurrentStep(QAR_CEREMONY_STEP.END)
    conferenceRoom.leave()
    jitsiConnection && jitsiConnection.disconnect()
  },
  setLEMultisigInceptPayload: (payload: CreateLEMultisigAidMessagePayload) => {
    set(
      produce((state: IMeetStore) => {
        state.ceremonySteps.multiSig.leMultisigAidInceptPayload = payload
      })
    )
  },
  logEvents: (eventMessages: string[]) => {
    set(
      produce((state: IMeetStore) => {
        state.ceremonySteps.eventLogs.push(...eventMessages)
      })
    )
  },
  setRecipient: (recipients) => {
    set(
      produce((state: IMeetStore) => {
        state.ceremonySteps.Recipients = recipients
      })
    )
  },
  initMeetSubscribers: () => {
    const unsubscribe = useStore.subscribe(
      (state) => state.ceremonySteps.AIDVerification.AIDTodos,
      (AIDTodos: any) => {
        const state = useStore.getState()

        state.checkAndActivateLARForAID(AIDTodos)

        const allChallengesComplete =
          AIDTodos.length > 0 &&
          !AIDTodos.some((lc) => lc.triggerSignify !== TriggerSignify.COMPLETE)

        if (allChallengesComplete) {
          useStore.setState({
            ceremonySteps: {
              ...state.ceremonySteps,
              AIDVerification: {
                ...state.ceremonySteps.AIDVerification,
                stepCompleted: true
              }
            }
          })
        }
      }
    )

    return unsubscribe
  },
  generateLARsVerificationsForAID: () => {
    const {
      sendMessage,
      authentication,
      ceremonySteps: { participants, Recipients }
    } = get()
    const ceremonyType = ceremonyTypeFromURL()

    let allParticipants: Participant[]
    let fromParticipants: Participant[]

    switch (ceremonyType) {
      case CEREMONY_TYPE.ECR:
      case CEREMONY_TYPE.OOR:
        const onlyRecipientParticipants = participants.filter((p) =>
          isRecipient(p.id, Recipients)
        )

        fromParticipants = participants.filter((p) => isLAR(p.role))
        allParticipants = onlyRecipientParticipants
        break
      default:
        const lars = participants.filter((p) => isLAR(p.role))

        fromParticipants = lars
        allParticipants = lars
        break
    }

    const aidStrings: AIDVerificationString[] = []

    allParticipants.forEach((part) => {
      const entryParticipant = {
        from: [],
        to: part.id,
        triggerSignify: TriggerSignify.WAITING,
        completed: false,
        messageId: crypto.randomUUID()
      }

      aidStrings.push(entryParticipant)
      const allParticipantsExceptTheCurrentOne = fromParticipants.filter(
        (participant) => participant.id !== part.id
      )

      // populate list of listeners
      allParticipantsExceptTheCurrentOne.forEach((fromParticipant) => {
        entryParticipant.from.push({
          participantId: fromParticipant.id,
          completionStatus: false
        })
      })

      // add another aid string for the lead QAR ( who's not present in the participants list )
      entryParticipant.from.push({
        // todo improve get leadQAR
        participantId: authentication.userInfo.id,
        completionStatus: false
      })
    })

    sendMessage(
      new JitsiMessageCreator(
        JitsiMessagesEnum.AID_VERIFICATION_GENERATE_LARS_TODOS,
        aidStrings
      )
    )
  },
  iSaidItForAID: (todo) => {
    const generalJitsiPayload: AIDVerificationString = {
      ...todo,
      triggerSignify: TriggerSignify.ACCEPT
    }

    get().sendMessage(
      new JitsiMessageCreator(
        JitsiMessagesEnum.AID_VERIFICATION_I_Said_It,
        generalJitsiPayload
      )
    )
  },
  iHeardItForAID: (todo) => {
    const { authentication, sendMessage } = get()
    const updatedTodo = cloneDeep(todo)
    const userFromEntry = updatedTodo.from.find(
      (from) => from.participantId === authentication.userInfo.id
    )
    userFromEntry.completionStatus = true

    const didAllParticipantsHeardItRight = updatedTodo.from.every(
      (from) => from.completionStatus === true
    )

    updatedTodo.triggerSignify = didAllParticipantsHeardItRight
      ? TriggerSignify.COMPLETE
      : updatedTodo.triggerSignify

    updatedTodo.completed = true

    sendMessage(
      new JitsiMessageCreator(
        JitsiMessagesEnum.AID_VERIFICATION_I_Heard_It,
        updatedTodo
      )
    )
  },
  checkAndActivateLARForAID: (todos) => {
    const {
      ceremonySteps: { activeLAR },
      sendMessage
    } = get()
    const currentUserChallenges = todos.filter((c) => c.to === activeLAR)

    const allChallengesComplete = currentUserChallenges.every(
      (lc) => lc.triggerSignify === TriggerSignify.COMPLETE
    )

    const waitingChallenge = todos.find(
      (lc) => lc.triggerSignify === TriggerSignify.WAITING
    )?.to
    const failedChallenge = todos.find(
      (lc) => lc.triggerSignify === TriggerSignify.FAILED
    )?.to

    if (allChallengesComplete) {
      sendMessage(prepareActiveLAR(waitingChallenge || '00')) // Defaults to '00' if no waiting challenge
    } else {
      // Check if any challenge has failed and update accordingly
      const hasFailedChallenge = currentUserChallenges.some(
        (lc) => lc.triggerSignify === TriggerSignify.FAILED
      )
      if (hasFailedChallenge && failedChallenge) {
        sendMessage(prepareActiveLAR(failedChallenge))
      }
    }

    if (!activeLAR || activeLAR === '00') {
      sendMessage(
        prepareActiveLAR(failedChallenge || waitingChallenge || activeLAR)
      )
    }
  },
  resetMeetStore: () => {
    set(initialState)
  }
})

export default meetStore
