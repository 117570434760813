import { Observable, interval, Subject } from 'rxjs'
import { switchMap, takeUntil } from 'rxjs/operators'
import { getUserNotifications } from '@/services/notification'
import { INotification } from '@/types/notification'
import NotificationEventType from '@/enums/NotificationEventType'
import useStore from '@/state/store'
import { generateAlias } from '@/utils/aid'
import constants from '@/config/constants'
import { UserInfo } from '@/state/authentication'
import { aid } from '@/state/signify'
import { findAidByAlias } from '@/services/wallet'
import { isEmpty } from 'lodash'
import { getAidDetail } from './signify'
import { agentAIDService } from '@/utils/apis'
import { SaveNativeAidRequest } from '@/api/origin-agent-svc'
import DefaultIdentifierType from '@/enums/origin-identifier-type'
import { saveIdentifierToAgent } from './wallet/agent'

class NotificationProcessor {
  private static instance: NotificationProcessor | null = null

  private pollInterval: number
  private stopPollingSubject: Subject<void>
  private notificationsSubject: Subject<any>

  private constructor(pollInterval: number) {
    this.pollInterval = pollInterval
    this.stopPollingSubject = new Subject<void>()
    this.notificationsSubject = new Subject<any>()
  }

  static getInstance(pollInterval: number) {
    if (!NotificationProcessor.instance) {
      NotificationProcessor.instance = new NotificationProcessor(pollInterval)
    }
    return NotificationProcessor.instance
  }

  async processOnce(): Promise<any> {
    let { userInfo } = useStore.getState().authentication
    if (!userInfo.id) return

    try {
      const notifications = await getUserNotifications(userInfo.id)
      // todo talk with Arsh / BE in order to update swagger this
      // @ts-ignore
      return this.processNotifications(notifications)
    } catch (ex) {
      return
    }
  }

  start(): void {
    interval(this.pollInterval)
      .pipe(
        switchMap(async () => {
          try {
            console.log('Fetching notifications ')
            let { userInfo } = useStore.getState().authentication
            if (userInfo.id) {
              const notifications = await getUserNotifications(userInfo.id)
              // todo talk with Arsh / BE in order to update swagger this
              // @ts-ignore
              return this.processNotifications(notifications)
            }
          } catch (e) {
            console.error('NOTIFICATION_HANDLER_ERROR :: ', e)
          }
        }),
        takeUntil(this.stopPollingSubject)
      )
      .subscribe()
  }

  stop(): void {
    this.stopPollingSubject.next()
  }

  subscribeToNotifications(): Observable<any> {
    return this.notificationsSubject.asObservable()
  }

  private processNotifications(
    notifications: INotification[]
  ): Observable<void> {
    notifications.forEach((notification) => {
      if (notification.channel?.toUpperCase() == 'AUTO_TODO') {
        this.handleAutoTodoNotification(notification)
      } else {
        this.handleUINotification(notification)
      }
      // this.notificationsSubject.next(notification)
    })

    return new Observable<void>((observer) => {
      observer.next()
      observer.complete()
    })
  }

  private async handleAutoTodoNotification(
    notification: INotification
  ): Promise<any> {
    try {
      console.info(
        `Processing auto-todo of type ${notification.description} `,
        JSON.stringify(notification)
      )
      const userAids: aid[] = await useStore.getState().getAids()
      const eventType = notification.description.toUpperCase()
      switch (eventType) {
        case NotificationEventType.AUTO_TODO_CREATE_DAR_AID.toUpperCase():
          useStore
            .getState()
            .addNotificationProcess(
              NotificationEventType.AUTO_TODO_CREATE_DAR_AID.toUpperCase()
            )
          let { userInfo } = useStore.getState().authentication
          if (isEmpty(userInfo.firstName) || isEmpty(userInfo.lastName)) break
          const org = userInfo?.orgs?.[0]
          let darAlias = generateAlias(
            `${userInfo.firstName} ${userInfo.lastName}`,
            constants.ROLES.DAR,
            org?.name
          )
          const darAid = findAidByAlias(userAids, darAlias)
          if (!darAid) {
            let newAid = await useStore.getState().createIdentifier(darAlias)
            if (newAid) {
              let aidDetail = await getAidDetail(
                useStore.getState().signifyClient,
                darAlias,
                'agent'
              )
              console.log('Storing DAR AID in Agent svc')
              await this.storeAidInDb(
                aidDetail,
                userInfo.id,
                userInfo.orgs[0].id,
                DefaultIdentifierType.DAR
              )
            }
          }
          useStore
            .getState()
            .removeNotificationProcess(
              NotificationEventType.AUTO_TODO_CREATE_DAR_AID.toUpperCase()
            )

          break
        case NotificationEventType.AUTO_TODO_CREATE_LAR_AID.toUpperCase():
          useStore
            .getState()
            .addNotificationProcess(
              NotificationEventType.AUTO_TODO_CREATE_LAR_AID.toUpperCase()
            )
          let { userInfo: lar } = useStore.getState().authentication
          if (isEmpty(lar.firstName) || isEmpty(lar.lastName)) break
          let larAlias = generateAlias(
            `${lar.firstName} ${lar.lastName}`,
            constants.ROLES.LAR,
            lar?.orgs?.[0]?.name
          )
          const larAid = findAidByAlias(userAids, larAlias)
          if (!larAid) {
            let newAid = await useStore.getState().createIdentifier(larAlias)
            if (newAid) {
              let aidDetail = await getAidDetail(
                useStore.getState().signifyClient,
                larAlias,
                'agent'
              )
              console.log('Storing LAR AID in Agent svc')
              await this.storeAidInDb(
                aidDetail,
                lar.id,
                lar.orgs[0].id,
                DefaultIdentifierType.LAR
              )
            }
          }
          useStore
            .getState()
            .removeNotificationProcess(
              NotificationEventType.AUTO_TODO_CREATE_LAR_AID.toUpperCase()
            )
          break
        case NotificationEventType.AUTO_TODO_USER_ONBOARDING_COMPLETE.toUpperCase():
          useStore
            .getState()
            .addNotificationProcess(
              NotificationEventType.AUTO_TODO_USER_ONBOARDING_COMPLETE.toUpperCase()
            )
          let { userInfo: originUser } = useStore.getState().authentication
          if (isEmpty(originUser.firstName) || isEmpty(originUser.lastName))
            break
          let genericUserAlias = generateAlias('', 'user', 'Origin')
          let originUserAlias = generateAlias(
            `${originUser.firstName} ${originUser.lastName}`,
            'user',
            'Origin'
          )
          const genericOriginUserAid = findAidByAlias(
            userAids,
            genericUserAlias
          )
          const originUserAid = findAidByAlias(userAids, originUserAlias)

          if (genericOriginUserAid && !originUserAid) {
            await useStore
              .getState()
              .renameIdentifier(genericUserAlias, originUserAlias)
          } else if (!originUserAid) {
            await useStore.getState().createIdentifier(originUserAlias)
            let aidDetail = await getAidDetail(
              useStore.getState().signifyClient,
              originUserAlias,
              'agent'
            )
            console.log('Storing Origin user AID in Agent svc')
            await this.storeAidInDb(
              aidDetail,
              originUser.id,
              originUser.orgs[0].id,
              DefaultIdentifierType.ORIGIN_USER
            )
          }

          if (originUser?.grants?.length > 0) {
            let hasEmpolyeeRole = originUser?.grants.some(
              (x) => x.toUpperCase() === constants.ROLES.EMPLOYEE.toUpperCase()
            )
            if (hasEmpolyeeRole == true) {
              let roleAlias = generateAlias(
                `${originUser.firstName} ${originUser.lastName}`,
                constants.ROLES.EMPLOYEE,
                originUser?.orgs?.[0]?.name
              )
              const roleAid = findAidByAlias(userAids, roleAlias)
              if (!roleAid) {
                let newAid = await useStore
                  .getState()
                  .createIdentifier(roleAlias)
                if (newAid) {
                  let aidDetail = await getAidDetail(
                    useStore.getState().signifyClient,
                    roleAlias,
                    'agent'
                  )
                  console.log('Storing employee AID in Agent svc')
                  await this.storeAidInDb(
                    aidDetail,
                    originUser.id,
                    originUser.orgs[0].id,
                    DefaultIdentifierType.EMPLOYEE
                  )
                }
              }
            }
          }
          useStore
            .getState()
            .removeNotificationProcess(
              NotificationEventType.AUTO_TODO_USER_ONBOARDING_COMPLETE.toUpperCase()
            )
          break
        case NotificationEventType.AUTO_TODO_CREATE_OOR_TITLE_AID.toUpperCase():
          useStore
            .getState()
            .addNotificationProcess(
              NotificationEventType.AUTO_TODO_CREATE_OOR_TITLE_AID.toUpperCase()
            )
          let { userInfo: oorUser } = useStore.getState().authentication
          if (isEmpty(oorUser.firstName) || isEmpty(oorUser.lastName)) break
          await this.createRoleTitleAid(oorUser, userAids)
          useStore
            .getState()
            .removeNotificationProcess(
              NotificationEventType.AUTO_TODO_CREATE_OOR_TITLE_AID.toUpperCase()
            )
          break
        case NotificationEventType.AUTO_TODO_CREATE_ECR_TITLE_AID.toUpperCase():
          useStore
            .getState()
            .addNotificationProcess(
              NotificationEventType.AUTO_TODO_CREATE_ECR_TITLE_AID.toUpperCase()
            )
          let { userInfo: ecrUser } = useStore.getState().authentication
          if (isEmpty(ecrUser.firstName) || isEmpty(ecrUser.lastName)) break
          await this.createRoleTitleAid(ecrUser, userAids)
          useStore
            .getState()
            .removeNotificationProcess(
              NotificationEventType.AUTO_TODO_CREATE_ECR_TITLE_AID.toUpperCase()
            )
          break
        default:
          console.warn(
            'Unknown notification auto-todo type:',
            eventType,
            notification
          )
      }
    } catch (e) {
      console.error('AUTO_TODO_HANDLER_ERROR :: ', e)
    }
  }

  private handleUINotification(notification: INotification): void {
    console.log('handleUINotification : ' + JSON.stringify(notification))
    //TODO: implement UI notification logic and show to user
  }

  private async createRoleTitleAid(userInfo: UserInfo, userAids: aid[]) {
    try {
      userInfo.grants.map(async (grant) => {
        let title = grant
          .toLowerCase()
          .split(' ')
          .map((t) => t.charAt(0).toUpperCase() + t.slice(1))
          .join('-')

        let titleAlias = generateAlias(
          `${userInfo.firstName} ${userInfo.lastName}`,
          title,
          userInfo?.orgs?.[0]?.name
        )

        const roleAid = findAidByAlias(userAids, titleAlias)
        if (!roleAid) {
          let newAid = await useStore.getState().createIdentifier(titleAlias)

          if (newAid) {
            let aidDetail = await getAidDetail(
              useStore.getState().signifyClient,
              titleAlias,
              'agent'
            )
            console.log('Storing ROLE AID in Agent svc')
            await this.storeAidInDb(
              aidDetail,
              userInfo.id,
              userInfo.orgs[0].id,
              title.toLowerCase()
            )
          }
        }
      })
    } catch (e) {
      console.error('ERROR_IN_CREATING_ROLE_TITLE_AID :: ', e)
    }
  }

  private async storeAidInDb(
    aidDetail: {
      aid: any
      alias: any
      oobi: string[]
      isMultisig: boolean
    },
    userId: string,
    orgId: string,
    aidType: string
  ): Promise<boolean> {
    const payload = {
      ...aidDetail,
      type: aidType,
      userId: userId,
      orgId: orgId
    }
    await saveIdentifierToAgent(payload)
    return true
  }
}

export default NotificationProcessor
